import * as React from "react";

export const Spinner = () => (
  <i
    className="fa fa-circle-o-notch fa-spin"
    style={{
      color: "grey",
      fontSize: 17,
    }}
  />
);
