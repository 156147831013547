import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ApplicationState } from "store";

import * as SitesState from "../../store/Sites";
import { AccessControlDoorType } from "api/ApiClient";
import SearchInput from "components/shared/SearchInput";

interface Props {
  siteId: string;
  typedLock: string;
  doorType: string;
  handleDoorType: (value: string) => void;
  handleSiteId: (value: string) => void;
  handleTypedLock: (value: string) => void;
  resetFilters: () => void;
}

export default function LocksFilters(props: Props) {
  const {
    typedLock,
    siteId,
    doorType,
    handleDoorType,
    handleSiteId,
    handleTypedLock,
  } = props;
  const dispatch = useDispatch();
  const { sites } = useSelector((state: ApplicationState) => state.sites);
  const { page, pageSize, searchTerm } = useSelector(
    (state: ApplicationState) => state.locks
  );

  useEffect(() => {
    dispatch(
      SitesState.actionCreators.setFetchParamSites({
        page,
        pageSize: 100,
        searchTerm,
      })
    );
  }, [dispatch, page, pageSize, searchTerm]);

  return (
    <div>
      <div style={{ display: "flex", gap: 8, justifyContent: "space-between" }}>
        <SearchInput
          id="typedLock"
          name="typedLock"
          placeholder="Search by lock name"
          value={typedLock}
          onChange={(e) => handleTypedLock(e.target.value)}
        />
        <div style={{ display: "flex", alignItems: "flex-start", gap: 8 }}>
          <div className="form-group">
            <label htmlFor="siteId">Sites</label>
            <select
              className="form-control"
              id="siteId"
              value={siteId}
              onChange={(e) => handleSiteId(e.target.value)}
            >
              <option value="">All Sites</option>
              {sites.map((site) => (
                <option key={site.id} value={site.id}>
                  {site.name}
                </option>
              ))}
            </select>
          </div>
          <div className="form-group">
            <label htmlFor="doorType">Door Type</label>
            <select
              className="form-control"
              value={doorType}
              onChange={(e) => handleDoorType(e.target.value)}
            >
              <option value="">All Door Types</option>
              {Object.keys(AccessControlDoorType)
                .filter((k) => isNaN(Number(k)))
                .map((k, i) => (
                  <option key={i} value={k}>
                    {k}
                  </option>
                ))}
            </select>
          </div>
        </div>
      </div>
    </div>
  );
}
