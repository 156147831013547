import * as React from "react";
import { connect } from "react-redux";
import { ApplicationState } from "../../store";
import * as ScreensState from "../../store/Screens";
import { PageControl } from "./PageControl";
import { debounce } from "lodash";
import { defaultPageSize, screenDeployButtonLabel } from "../../helpers";
import { ScreenResponse, TileFragmentResponse } from "../../api/ApiClient";
import { Spinner } from "./Spinner";
import { Link } from "react-router-dom";
import SearchInput from "./SearchInput";

interface Injected {
  screens: ScreensState.ScreensState;
}

type OwnProps = {
  parentScreen?: ScreenResponse;
  screensProp: ScreenResponse[] | ScreenResponse | null;
  deployChildScreenToParent?: (childScreen: ScreenResponse) => any;
  onSelected: (site: ScreenResponse) => any;
  pageSize?: number;
  selectedScreens?: Array<ScreenResponse>;
  renderButton?: (site: ScreenResponse) => any;
  selectedLabel?: string;
  selectedBtnClass?: string;
  unselectedLabel?: string;
  unselectedBtnClass?: string;
  btnStyle?: Object;
};

type Props = Injected & typeof ScreensState.actionCreators & OwnProps;

interface State {
  typedScreen: string;
}

class SelectScreen extends React.Component<Props, State> {
  state: State = {
    typedScreen: "",
  };

  componentDidMount() {
    if (!this.props.screensProp) {
      console.log("SelectScreen: Requesting screens...");
      this.refresh();
    }
  }

  componentDidUpdate(prevProps: Props) {
    if (!this.props.screensProp) {
      if (this.props.pageSize !== prevProps.pageSize) {
        this.refresh();
      }
    }
  }

  refresh = async () => {
    try {
      const { pageSize } = this.props;
      const { typedScreen: searchTerm } = this.state;
      await this.props.setFetchParamScreens({
        page: 0,
        pageSize: pageSize || defaultPageSize,
        searchTerm,
        showChildren: false,
      });
    } catch (e) {
      console.error("Error requesting screens", e);
      alert("Error requesting screens: " + JSON.stringify(e));
    }
  };

  handleTypedScreen = (value: string) => {
    this.setState({ typedScreen: value }, this.searchScreen);
  };

  deployChildScreenToParent = async (childScreen: ScreenResponse) => {
    this.props.deployChildScreenToParent &&
      this.props.deployChildScreenToParent(childScreen);
  };

  searchScreen = debounce(this.refresh, 1000);

  public render() {
    if (this.props.screensProp) {
      return this.renderScreensTable(this.props.screensProp, {
        showPagination: false,
      });
    }

    const { screens, searchTerm, isLoading } = this.props.screens;

    const { typedScreen } = this.state;

    return (
      <div>
        <SearchInput
          id="typedScreen"
          name="typedScreen"
          placeholder="type here"
          value={typedScreen}
          onChange={(e) => this.handleTypedScreen(e.target.value)}
        />
        <h4>
          {searchTerm
            ? `Screen Tiles Matching '${searchTerm}'`
            : "Screen Tiles"}
          {isLoading && <Spinner />}
        </h4>

        {this.renderScreensTable(screens)}
      </div>
    );
  }

  private defaultRenderButton = (screen: ScreenResponse) => {
    const {
      selectedScreens,
      onSelected,
      btnStyle,
      selectedBtnClass,
      selectedLabel,
      unselectedBtnClass,
      unselectedLabel,
    } = this.props;
    const isSelected =
      selectedScreens && selectedScreens.find((a) => a.id === screen.id);
    const className = isSelected
      ? selectedBtnClass || "btn-primary"
      : unselectedBtnClass || "btn-default";
    return (
      <button
        type="button"
        className={`btn ${className}`}
        style={btnStyle}
        onClick={() => onSelected(screen)}
      >
        {!isSelected
          ? unselectedLabel || "Select"
          : selectedLabel || "Unselect"}
      </button>
    );
  };

  private renderScreensTable(
    screensArg: ScreenResponse[] | ScreenResponse,
    { showPagination } = { showPagination: true }
  ) {
    const { renderButton } = this.props;
    const { isLoading } = this.props.screens;

    const screens = (
      (screensArg as any).length !== undefined ? screensArg : [screensArg]
    ) as ScreenResponse[];

    return (
      <div>
        {screens && screens.length > 0 && screens[0].parentScreenId && (
          <Link
            style={{ marginLeft: 5 }}
            to={`/screens/${screens[0].parentScreenId}`}
          >
            View Target Screen
          </Link>
        )}
        <table className="table table-hover">
          <thead>
            <tr>
              <th />
              <th>Tile Id</th>
              <th>Name</th>
              <th>Tenant</th>
            </tr>
          </thead>
          <tbody>
            {screens.map((screen, i) => {
              var isDeployed =
                this.props.parentScreen &&
                this.props.parentScreen.deployedFromScreenId === screen.id;

              return (
                <React.Fragment key={"p_" + i}>
                  <tr key={"f_" + i}>
                    <td style={{ width: 100 }}>
                      {renderButton
                        ? renderButton(screen)
                        : this.defaultRenderButton(screen)}
                    </td>
                    <td>
                      {screen.appId}
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                      >
                        <a
                          href={previewLink(screen)}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Preview
                        </a>

                        {!screen.parentScreenId && (
                          <React.Fragment>
                            <Link
                              style={{ marginLeft: 5 }}
                              to={`/screens/${screen.id}?children=true`}
                            >
                              <b className="text-warning small">
                                View Proposals, Versions
                              </b>
                            </Link>
                          </React.Fragment>
                        )}
                      </div>
                      {this.props.deployChildScreenToParent && (
                        <div style={{ marginTop: 20 }}>
                          <button
                            className="btn btn-sm btn-warning"
                            type="button"
                            disabled={
                              false /*version might have changed since deploy, allow re-deploy*/
                            }
                            onClick={() =>
                              this.deployChildScreenToParent(screen)
                            }
                          >
                            <b>
                              {screen.screenChildType
                                ? screenDeployButtonLabel(
                                    screen.screenChildType
                                  )
                                : ""}
                            </b>
                          </button>
                          {isDeployed && (
                            <b className="small text-info">
                              &nbsp;&nbsp;Deployed
                            </b>
                          )}
                        </div>
                      )}
                    </td>
                    <td>{screen.name}</td>
                    <td>
                      {screen.account && screen.account.ownerTenant
                        ? screen.account.ownerTenant.name
                        : ""}
                    </td>
                  </tr>
                  <tr>
                    <td style={{ borderTopWidth: 0 }} />
                    <td style={{ borderTopWidth: 0 }} colSpan={3}>
                      <b>
                        {(screen.playerIds || [])
                          .map((p) => p.playerId)
                          .join(", ")}
                      </b>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ borderTopWidth: 0 }} />
                    <td style={{ borderTopWidth: 0 }} colSpan={3}>
                      <h6 className="text-muted">Fragments:</h6>
                      <ul>
                        {(screen.tileFragments || []).map((t, i) => (
                          <TileFragmentComp key={"t_" + i} tile={t} />
                        ))}
                      </ul>
                    </td>
                  </tr>
                </React.Fragment>
              );
            })}
          </tbody>
        </table>
        {showPagination && (
          <PageControl
            onNext={this.props.nextScreenPage}
            onPrev={this.props.prevScreenPage}
            currentPage={this.props.screens.page}
            isLoading={isLoading}
            disablePrev={this.props.screens.page === 0}
            disableNext={
              this.props.screens.screens.length < this.props.screens.pageSize
            }
          />
        )}
      </div>
    );
  }
}

const TileFragmentComp = ({ tile }: { tile: TileFragmentResponse }) => (
  <li>
    <div>
      <p>
        <b>{tile.text}</b>
      </p>
    </div>
    {tile.imageUrl && (
      <img
        src={tile.imageUrl}
        alt={tile.description}
        style={{ width: 100, height: 100 }}
      />
    )}
    <div />
    <br />
  </li>
);

function previewLink(screen: ScreenResponse) {
  const baseUrl = "https://livly-connect.firebaseapp.com";
  const firstPlayerId =
    ((screen.playerIds || [])[0] || {}).playerId || "noPlayerId";
  return `${baseUrl}/?appId=${screen.appId}&playerId=${firstPlayerId}`;
}

export default connect<any, any, OwnProps, ApplicationState>(
  (state) => ({
    screens: state.screens,
  }),
  { ...ScreensState.actionCreators }
)(SelectScreen);
