import * as React from "react";

type PageControlProps = {
  currentPage: number;
  isLoading?: boolean;
  onNext: () => any;
  onPrev: () => any;
  pageSize?: number;
  setPageSize?: (pageSize: number) => void;
  disableNext?: boolean;
  disablePrev?: boolean;
};

export class PageControl extends React.Component<PageControlProps, {}> {
  onNext = async (e: any) => {
    const { isLoading } = this.props;
    e.preventDefault();
    if (this.props.disableNext || isLoading) return;
    try {
      await this.props.onNext();
    } catch (e) {
      alert("Error fetching next page: " + JSON.stringify(e));
    }
  };

  onPrev = async (e: any) => {
    const { isLoading } = this.props;
    e.preventDefault();
    if (this.props.disablePrev || isLoading) return;
    try {
      await this.props.onPrev();
    } catch (e) {
      alert("Error fetching previous page: " + JSON.stringify(e));
    }
  };

  onSelectPageSize = (e: React.ChangeEvent) => {
    const { value } = e.target as HTMLSelectElement;
    const pageSize = parseInt(value, 10);
    if (this.props.setPageSize) {
      this.props.setPageSize(pageSize);
    }
  };

  public render() {
    const { currentPage } = this.props;

    return (
      <ul
        style={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          gap: 12,
          listStyle: "none",
          padding: 0,
          margin: 0,
        }}
      >
        {this.props.setPageSize ? (
          <li>
            <span>Rows per page: </span>
            <select
              value={this.props.pageSize?.toString()}
              onChange={this.onSelectPageSize}
            >
              <option value="10">10</option>
              <option value="30">30</option>
              <option value="50">50</option>
            </select>
          </li>
        ) : null}

        <li>
          <button onClick={this.onPrev}>Prev</button>
        </li>
        <li className="active">
          <span>Page {currentPage + 1}</span>
        </li>
        <li>
          <button onClick={this.onNext}>Next</button>
        </li>
      </ul>
    );
  }
}
