import * as React from "react";
import { connect } from "react-redux";
import { ApplicationState } from "../../store";
import * as LocksState from "../../store/Locks";
import { PageControl } from "./PageControl";
import { debounce } from "lodash";
import { defaultPageSize } from "../../helpers";
import { LockResponse } from "../../api/ApiClient";
import { Spinner } from "./Spinner";
import LocksFilters from "components/Locks/Filters";

interface Injected {
  locks: LocksState.LocksState;
}

type OwnProps = {
  onSelected: (lock: LockResponse) => any;
  selectedLocks?: Array<LockResponse>;
  pageSize?: number;
  showFirst?: number | null | undefined;
  inProgressLocks?: Array<LockResponse>;
  disabledLocks?: Array<LockResponse>;
  renderButton?: (lock: LockResponse) => any;
  inProgressLabel?: string;
  selectedLabel?: string;
  unselectedLabel?: string;
  selectedBtnClass?: string;
  unselectedBtnClass?: string;
  btnStyle?: Object;
};

type Props = Injected & typeof LocksState.actionCreators & OwnProps;

interface State {
  typedLock: string;
  siteId: string;
  doorType: string;
}

const LockIndicator = (lock: LockResponse) => {
  let badgeClass = "badge";
  let badgeText = "online";
  if (lock.lastSeenTimeStamp) {
    let now = new Date().getTime();
    let lockLastSeen = new Date(lock.lastSeenTimeStamp + "Z").getTime();
    let difference = (now - lockLastSeen) / 1000 / 60;
    if (difference <= 5) {
      badgeClass += " alert-success";
    } else if (difference <= 20) {
      badgeClass += " alert-warning";
    } else {
      badgeClass += " alert-danger";
      badgeText = "offline";
    }
  }
  return (
    <span>
      {lock.name}{" "}
      {lock.lastSeenTimeStamp && (
        <span
          className={badgeClass}
          data-toggle="tooltip"
          title={
            "Last Seen: " +
            new Date(lock.lastSeenTimeStamp + "Z").toLocaleString()
          }
        >
          {badgeText}
        </span>
      )}{" "}
    </span>
  );
};

class SelectLock extends React.Component<Props, State> {
  state = {
    typedLock: "",
    siteId: "",
    doorType: "",
  };

  componentDidMount() {
    console.log("SelectLock: Requesting locks...");
    this.refresh();
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.pageSize !== prevProps.pageSize) {
      this.refresh();
    }
  }

  refresh = async () => {
    try {
      const { pageSize } = this.props;
      const { typedLock: searchTerm, doorType, siteId } = this.state;
      await this.props.setFetchParamsLocks({
        page: 0,
        pageSize: pageSize || defaultPageSize,
        searchTerm,
        doorType,
        siteId,
      });
    } catch (e) {
      console.error("Error requesting locks", e);
      alert("Error requesting locks: " + JSON.stringify(e));
    }
  };

  handleTypedLock = (value: string) => {
    this.setState({ typedLock: value }, this.searchLock);
  };

  handleSiteId = (value: string) => {
    this.setState({ siteId: value }, this.searchLock);
  };

  handleDoorType = (value: string) => {
    this.setState({ doorType: value }, this.searchLock);
  };

  resetFilters = () => {
    this.setState({ typedLock: "", siteId: "", doorType: "" }, this.searchLock);
  };

  searchLock = debounce(this.refresh, 1000);

  public render() {
    const { locks, isLoading } = this.props.locks;

    return (
      <div>
        <LocksFilters
          {...this.state}
          handleTypedLock={this.handleTypedLock}
          handleSiteId={this.handleSiteId}
          handleDoorType={this.handleDoorType}
          resetFilters={this.resetFilters}
        />
        <h4 style={{ display: "flex", alignItems: "center", gap: 8 }}>
          Locks
          {isLoading && <Spinner />}
        </h4>
        {this.renderLocksTable(locks)}
      </div>
    );
  }

  private defaultRenderButton = (lock: LockResponse) => {
    const {
      selectedLocks,
      inProgressLocks,
      disabledLocks,
      onSelected,
      inProgressLabel,
      selectedLabel,
      unselectedLabel,
      selectedBtnClass,
      unselectedBtnClass,
      btnStyle,
    } = this.props;

    const isSelected =
      selectedLocks && selectedLocks.find((l) => l.id === lock.id);

    const isInProgress =
      inProgressLocks && inProgressLocks.find((l) => l.id === lock.id);

    const isDisabledLock =
      disabledLocks && disabledLocks.find((l) => l.id === lock.id);

    const className = isSelected
      ? selectedBtnClass || "btn-primary"
      : unselectedBtnClass || "btn-default";

    const disabled = !!isInProgress || !!isDisabledLock;

    return (
      <button
        className={`btn ${className}`}
        type="button"
        style={btnStyle}
        disabled={disabled}
        onClick={(_: any) => onSelected(lock)}
      >
        {isInProgress
          ? inProgressLabel || "Opening..."
          : isSelected
          ? selectedLabel || "Select"
          : unselectedLabel || "Unselect"}
      </button>
    );
  };

  private renderLocksTable(locks: LockResponse[]) {
    const { renderButton, showFirst } = this.props;
    const { isLoading } = this.props.locks;
    locks = !showFirst ? locks : (locks || []).slice(0, showFirst);

    return (
      <div>
        <table className="table table-hover">
          <thead>
            <tr>
              <th>Lock Name</th>
              <th>Site</th>
              <th>Door Type</th>
              <th>SystemLockId</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {locks.map((lock, i) => (
              <tr key={"f_" + i}>
                <td style={{ paddingTop: 20 }}>
                  <LockIndicator {...lock} />
                </td>
                <td style={{ paddingTop: 20 }}>
                  {lock && lock.site ? lock.site.name : ""}
                </td>
                <td style={{ paddingTop: 20 }}>{lock.doorType}</td>

                <td style={{ paddingTop: 20 }}>{lock.systemLockId}</td>
                <td>
                  {renderButton
                    ? renderButton(lock)
                    : this.defaultRenderButton(lock)}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        {!showFirst && (
          <PageControl
            onNext={this.props.nextLockPage}
            onPrev={this.props.prevLockPage}
            setPageSize={this.props.setPageSize}
            pageSize={this.props.pageSize || defaultPageSize}
            currentPage={this.props.locks.page}
            isLoading={isLoading}
            disablePrev={this.props.locks.page === 0}
            disableNext={
              this.props.locks.locks.length < this.props.locks.pageSize
            }
          />
        )}
      </div>
    );
  }
}

export default connect<any, any, OwnProps, ApplicationState>(
  (state) => ({
    locks: state.locks,
  }),
  { ...LocksState.actionCreators }
)(SelectLock);
