import * as React from "react";
import { connect } from "react-redux";
import { ApplicationState } from "../../store";
import * as AccountsState from "../../store/Accounts";
import { PageControl } from "./PageControl";
import { debounce } from "lodash";
import { defaultPageSize } from "../../helpers";
import { AccountResponse } from "../../api/ApiClient";
import { Spinner } from "./Spinner";
import SearchInput from "./SearchInput";

interface Injected {
  accounts: AccountsState.AccountsState;
}

type OwnProps = {
  onSelected: (site: AccountResponse) => any;
  pageSize?: number;
  selectedAccounts?: Array<AccountResponse>;
  renderButton?: (site: AccountResponse) => any;
  selectedLabel?: string;
  selectedBtnClass?: string;
  unselectedLabel?: string;
  unselectedBtnClass?: string;
  btnStyle?: Object;
};

type Props = Injected & typeof AccountsState.actionCreators & OwnProps;

interface State {
  typedAccount: string;
}

class SelectAccount extends React.Component<Props, State> {
  state: State = {
    typedAccount: "",
  };

  componentDidMount() {
    console.log("SelectAccount: Requesting accounts...");
    this.refresh();
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.pageSize !== prevProps.pageSize) {
      this.refresh();
    }
  }

  refresh = async () => {
    try {
      const { pageSize } = this.props;
      const { typedAccount: searchTerm } = this.state;
      await this.props.setFetchParamAccounts({
        page: 0,
        pageSize: pageSize || defaultPageSize,
        searchTerm,
      });
    } catch (e) {
      console.error("Error requesting accounts", e);
      alert("Error requesting accounts: " + e);
    }
  };

  handleTypedAccount = (value: string) => {
    this.setState({ typedAccount: value }, this.searchAccount);
  };

  searchAccount = debounce(this.refresh, 1000);

  public render() {
    const { accounts, searchTerm, isLoading } = this.props.accounts;

    const { typedAccount } = this.state;

    return (
      <div>
        <SearchInput
          id="typedAccount"
          name="typedAccount"
          placeholder="type here"
          value={typedAccount}
          onChange={(e) => this.handleTypedAccount(e.target.value)}
        />
        <h4>
          {searchTerm ? `Accounts Matching '${searchTerm}'` : "Accounts"}
          {isLoading && <Spinner />}
        </h4>
        {this.renderAccountsTable(accounts)}
      </div>
    );
  }

  private defaultRenderButton = (account: AccountResponse) => {
    const {
      selectedAccounts,
      onSelected,
      btnStyle,
      selectedBtnClass,
      selectedLabel,
      unselectedBtnClass,
      unselectedLabel,
    } = this.props;
    const isSelected =
      selectedAccounts && selectedAccounts.find((a) => a.id === account.id);
    const className = isSelected
      ? selectedBtnClass || "btn-primary"
      : unselectedBtnClass || "btn-default";
    return (
      <button
        type="button"
        className={`btn ${className}`}
        style={btnStyle}
        onClick={() => onSelected(account)}
      >
        {!isSelected
          ? unselectedLabel || "Select"
          : selectedLabel || "Unselect"}
      </button>
    );
  };

  private renderAccountsTable(accounts: AccountResponse[]) {
    const { renderButton } = this.props;
    const { isLoading } = this.props.accounts;

    return (
      <div>
        <table className="table table-hover">
          <thead>
            <tr>
              <th />
              <th>Id</th>
              <th>Name</th>
              <th>SystemType</th>
            </tr>
          </thead>
          <tbody>
            {accounts.map((account, i) => (
              <tr key={"f_" + i}>
                <td style={{ width: 100 }}>
                  {renderButton
                    ? renderButton(account)
                    : this.defaultRenderButton(account)}
                </td>
                <td>{account.id}</td>
                <td>{account.name}</td>
                <td>{account.systemType}</td>
              </tr>
            ))}
          </tbody>
        </table>
        {true && (
          <PageControl
            onNext={this.props.nextAccountPage}
            onPrev={this.props.prevAccountPage}
            currentPage={this.props.accounts.page}
            isLoading={isLoading}
            disablePrev={this.props.accounts.page === 0}
            disableNext={
              this.props.accounts.accounts.length < this.props.accounts.pageSize
            }
          />
        )}
      </div>
    );
  }
}

export default connect<any, any, OwnProps, ApplicationState>(
  (state) => ({
    accounts: state.accounts,
  }),
  { ...AccountsState.actionCreators }
)(SelectAccount);
