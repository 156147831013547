import * as React from "react";
import { connect } from "react-redux";
import { ApplicationState } from "../../store";
import * as SharedAccountCredentialsState from "../../store/SharedAccountCredentials";
import { PageControl } from "./PageControl";
import { debounce } from "lodash";
import { defaultPageSize } from "../../helpers";
import { SharedAccountCredentialsResponse } from "../../api/ApiClient";
import { Spinner } from "./Spinner";
import SearchInput from "./SearchInput";

interface Injected {
  sharedAccountCredentials: SharedAccountCredentialsState.SharedAccountCredentialsState;
}

type OwnProps = {
  onSelected: (cred: SharedAccountCredentialsResponse) => any;
  pageSize?: number;
  showFirst?: number | null | undefined;
  selected?: Array<SharedAccountCredentialsResponse>;
  renderButton?: (cred: SharedAccountCredentialsResponse) => any;
  selectedLabel?: string;
  selectedBtnClass?: string;
  unselectedLabel?: string;
  unselectedBtnClass?: string;
  btnStyle?: Object;
};

type Props = Injected &
  typeof SharedAccountCredentialsState.actionCreators &
  OwnProps;

interface State {
  typed: string;
}

class SelectSharedAccountCredentials extends React.Component<Props, State> {
  state: State = {
    typed: "",
  };

  componentDidMount() {
    console.log("SelectSharedCred: Requesting...");
    this.refresh();
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.pageSize !== prevProps.pageSize) {
      this.refresh();
    }
  }

  refresh = async () => {
    try {
      const { pageSize } = this.props;
      const { typed: searchTerm } = this.state;
      await this.props.setFetchParamSharedCredentials({
        page: 0,
        pageSize: pageSize || defaultPageSize,
        searchTerm,
      });
    } catch (e) {
      console.error("Error requesting shared creds", e);
      alert("Error requesting shared creds: " + JSON.stringify(e));
    }
  };

  handleTyped = (value: string) => {
    this.setState({ typed: value }, this.search);
  };

  search = debounce(this.refresh, 1000);

  public render() {
    const { sharedCredentials, searchTerm, isLoading } =
      this.props.sharedAccountCredentials;

    const { typed } = this.state;

    return (
      <div>
        <SearchInput
          id="typedSharedCred"
          name="typedSharedCred"
          placeholder="type here"
          value={typed}
          onChange={(e) => this.handleTyped(e.target.value)}
        />
        <h4>
          {searchTerm ? `Credentials Matching '${searchTerm}'` : "Credentials"}
          {isLoading && <Spinner />}
        </h4>
        {this.renderAccountsTable(sharedCredentials)}
      </div>
    );
  }

  private defaultRenderButton = (
    sharedAccountCredentials: SharedAccountCredentialsResponse
  ) => {
    const {
      selected,
      onSelected,
      btnStyle,
      selectedBtnClass,
      selectedLabel,
      unselectedBtnClass,
      unselectedLabel,
    } = this.props;
    const isSelected =
      selected && selected.find((a) => a.id === sharedAccountCredentials.id);
    const className = isSelected
      ? selectedBtnClass || "btn-primary"
      : unselectedBtnClass || "btn-default";
    return (
      <button
        type="button"
        className={`btn ${className}`}
        style={btnStyle}
        onClick={() => onSelected(sharedAccountCredentials)}
      >
        {!isSelected
          ? unselectedLabel || "Select"
          : selectedLabel || "Unselect"}
      </button>
    );
  };

  private renderAccountsTable(creds: SharedAccountCredentialsResponse[]) {
    const { renderButton, showFirst } = this.props;
    const { isLoading } = this.props.sharedAccountCredentials;
    creds = !showFirst ? creds : (creds || []).slice(0, showFirst);

    return (
      <div>
        <table className="table table-hover">
          <thead>
            <tr>
              <th />
              <th>Id</th>
              <th>Name</th>
              <th>Description</th>
              <th>SystemType</th>
            </tr>
          </thead>
          <tbody>
            {creds.map((cred, i) => (
              <tr key={"f_" + i}>
                <td style={{ width: 100 }}>
                  {renderButton
                    ? renderButton(cred)
                    : this.defaultRenderButton(cred)}
                </td>
                <td>{cred.id}</td>
                <td>{cred.name}</td>
                <td>{cred.description}</td>
                <td>{cred.systemType}</td>
              </tr>
            ))}
          </tbody>
        </table>
        {!showFirst && (
          <PageControl
            onNext={this.props.nextSharedCredentialsPage}
            onPrev={this.props.prevSharedCredentialsPage}
            currentPage={this.props.sharedAccountCredentials.page}
            isLoading={isLoading}
            disablePrev={this.props.sharedAccountCredentials.page === 0}
            disableNext={
              this.props.sharedAccountCredentials.sharedCredentials.length <
              this.props.sharedAccountCredentials.pageSize
            }
          />
        )}
      </div>
    );
  }
}

export default connect<any, any, OwnProps, ApplicationState>(
  (state) => ({
    sharedAccountCredentials: state.sharedAccountCredentials,
  }),
  { ...SharedAccountCredentialsState.actionCreators }
)(SelectSharedAccountCredentials);
