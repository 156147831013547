import * as React from "react";
import { connect } from "react-redux";
import { ApplicationState } from "../../store";
import * as SiteUsersState from "../../store/SiteUsers";
import { PageControl } from "./PageControl";
import { debounce } from "lodash";
import { defaultPageSize } from "../../helpers";
import { SiteUserResponse } from "../../api/ApiClient";
import { Spinner } from "./Spinner";
import SearchInput from "./SearchInput";

interface Injected {
  siteUsers: SiteUsersState.SiteUsersState;
}

type OwnProps = {
  onSelected: (lock: SiteUserResponse) => any;
  pageSize?: number;
  selectedSiteUsers?: Array<SiteUserResponse>;
  inProgressSiteUsers?: Array<SiteUserResponse>;
  disabledSiteUsers?: Array<SiteUserResponse>;
  renderButton?: (lock: SiteUserResponse) => any;
  inProgressLabel?: string;
  selectedLabel?: string;
  unselectedLabel?: string;
  selectedBtnClass?: string;
  unselectedBtnClass?: string;
  btnStyle?: Object;
};

type Props = Injected & typeof SiteUsersState.actionCreators & OwnProps;

interface State {
  typedSiteUser: string;
}

class SelectSiteUser extends React.Component<Props, State> {
  state = {
    typedSiteUser: "",
  };

  componentDidMount() {
    console.log("SelectSiteUser: Requesting site users...");
    this.refresh();
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.pageSize !== prevProps.pageSize) {
      this.refresh();
    }
  }

  refresh = async () => {
    try {
      const { pageSize } = this.props;
      const { typedSiteUser: searchTerm } = this.state;
      await this.props.setFetchParamsSiteUsers({
        page: 0,
        pageSize: pageSize || defaultPageSize,
        searchTerm,
      });
    } catch (e) {
      console.error("Error requesting site users", e);
      alert("Error requesting site users: " + e);
    }
  };

  handleTypedSiteUser = (value: string) => {
    this.setState({ typedSiteUser: value }, this.searchSiteUser);
  };

  searchSiteUser = debounce(this.refresh, 1000);

  public render() {
    const { siteUsers, searchTerm, isLoading } = this.props.siteUsers;

    const { typedSiteUser } = this.state;

    return (
      <div>
        <SearchInput
          name="typedSiteUser"
          placeholder="type here"
          value={typedSiteUser}
          onChange={(e) => this.handleTypedSiteUser(e.target.value)}
        />
        <h4>
          {searchTerm ? `Site Users Matching '${searchTerm}'` : "Site Users"}
          {isLoading && <Spinner />}
        </h4>
        {this.renderSiteUsersTable(siteUsers)}
      </div>
    );
  }

  private defaultRenderButton = (siteUser: SiteUserResponse) => {
    const {
      selectedSiteUsers,
      inProgressSiteUsers,
      disabledSiteUsers,
      onSelected,
      inProgressLabel,
      selectedLabel,
      unselectedLabel,
      selectedBtnClass,
      btnStyle,
      unselectedBtnClass,
    } = this.props;

    const isSelected =
      selectedSiteUsers && selectedSiteUsers.find((l) => l.id === siteUser.id);

    const isInProgress =
      inProgressSiteUsers &&
      inProgressSiteUsers.find((l) => l.id === siteUser.id);

    const isDisabledSiteUser =
      disabledSiteUsers && disabledSiteUsers.find((l) => l.id === siteUser.id);

    const className = isSelected
      ? selectedBtnClass || "btn-primary"
      : unselectedBtnClass || "btn-default";

    const disabled = !!isInProgress || !!isDisabledSiteUser;

    return (
      <button
        className={`btn ${className}`}
        type="button"
        style={btnStyle}
        disabled={disabled}
        onClick={(_: any) => onSelected(siteUser)}
      >
        {isInProgress
          ? inProgressLabel || "Deleting..."
          : isSelected
          ? selectedLabel || "Delete"
          : unselectedLabel || "Delete"}
      </button>
    );
  };

  private renderSiteUsersTable(siteUsers: SiteUserResponse[]) {
    const { renderButton } = this.props;
    const { isLoading } = this.props.siteUsers;

    const pageControl = (
      <PageControl
        onNext={this.props.nextSiteUserPage}
        onPrev={this.props.prevSiteUserPage}
        currentPage={this.props.siteUsers.page}
        isLoading={isLoading}
        disablePrev={this.props.siteUsers.page === 0}
        disableNext={
          this.props.siteUsers.siteUsers.length < this.props.siteUsers.pageSize
        }
      />
    );

    return (
      <div>
        <table className="table table-hover">
          <thead>
            <tr>
              <th />
              <th>Site</th>
              <th>User</th>
              <th>SystemUserId</th>
              <th>CredentialId</th>
            </tr>
          </thead>
          <tbody>
            {siteUsers.map((siteUser, i) => (
              <tr key={"f_" + i}>
                <td>
                  {renderButton
                    ? renderButton(siteUser)
                    : this.defaultRenderButton(siteUser)}
                </td>
                <td>{siteUser.site ? siteUser.site.name : ""}</td>
                <td>{siteUser.user ? siteUser.user.name : ""}</td>
                <td>{siteUser.systemUserId}</td>
                <td>{siteUser.credentialId}</td>
              </tr>
            ))}
          </tbody>
        </table>
        {pageControl}
      </div>
    );
  }
}

export default connect<any, any, OwnProps, ApplicationState>(
  (state) => ({
    siteUsers: state.siteUsers,
  }),
  { ...SiteUsersState.actionCreators }
)(SelectSiteUser);
