import React from "react";

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {}

export default function SearchInput(props: Props) {
  return (
    <div className="form-group">
      <label htmlFor={props.id}>Search</label>
      <input
        className="form-control"
        {...props}
        value={props.value}
        onChange={props.onChange}
      />
    </div>
  );
}
