import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ApplicationState } from "store";

import * as SitesState from "../../store/Sites";
import SearchInput from "components/shared/SearchInput";

interface Props {
  typedAccount: string;
  groupType: string;
  propertyExternalId: string;
  handleGroupType: (value: string) => void;
  handleTypedAccount: (value: string) => void;
  handlePropertyExternalId: (value: string) => void;
}

export default function AccessControlGroupsFilters(props: Props) {
  const {
    typedAccount,
    groupType,
    handleGroupType,
    handleTypedAccount,
    propertyExternalId,
    handlePropertyExternalId,
  } = props;
  const dispatch = useDispatch();
  const { page, pageSize, searchTerm } = useSelector(
    (state: ApplicationState) => state.accessControlGroups
  );

  useEffect(() => {
    dispatch(
      SitesState.actionCreators.setFetchParamSites({
        page,
        pageSize: 100,
        searchTerm,
      })
    );
  }, [dispatch, page, pageSize, searchTerm]);

  return (
    <div>
      <div style={{ display: "flex", gap: 8, justifyContent: "space-between" }}>
        <SearchInput
          id="typedAccount"
          name="typedAccount"
          placeholder="Search by access control group name"
          value={typedAccount}
          onChange={(e) => handleTypedAccount(e.target.value)}
        />
        <div style={{ display: "flex", alignItems: "flex-start", gap: 8 }}>
          <SearchInput
            id="propertyExternalId"
            name="propertyExternalId"
            placeholder="Search by property external id"
            value={propertyExternalId}
            onChange={(e) => handlePropertyExternalId(e.target.value)}
            style={{ width: 250 }}
          />
          <div className="form-group">
            <label htmlFor="groupType">Type</label>
            <select
              id="groupType"
              className="form-control"
              value={groupType}
              onChange={(e) => handleGroupType(e.target.value)}
            >
              <option value="">All Group Types</option>
              <option value="regular">Regular</option>
              <option value="defaultForProperty">DefaultForProperty</option>
              <option value="unit">Unit</option>
              <option value="defaultForGuests">DefaultForGuests</option>
            </select>
          </div>
        </div>
      </div>
    </div>
  );
}
