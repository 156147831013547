import { Action, Reducer } from "redux";
import { AppThunkAction } from ".";
import {
  FetchParams,
  reduceSetFetchParams,
  defaultPageSize,
  reduceNextPage,
  reducePrevPage,
  apiClientFactory,
} from "../helpers";

import {
  KeyRequestCredentialType,
  AccessControlGroupType,
  AccessControlGroupResponse,
} from "../api/ApiClient";
import { BASE_API_URL } from "./Me";
import axios from "axios";

export interface AccessControlGroupsFiltersParams extends FetchParams {
  groupType: string;
  propertyExternalId: string;
}

export interface AccessControlGroupsState
  extends AccessControlGroupsFiltersParams {
  isLoading: boolean;
  accessControlGroups: AccessControlGroupResponse[];
}

interface RequestAccessControlGroupsAction {
  type: "REQUEST_ACCESS_CONTROL_GROUPS";
}

interface SetFetchParams extends AccessControlGroupsFiltersParams {
  type: "SETFETCHPARAMS_ACCESS_CONTROL_GROUPS";
}

interface NextPage {
  type: "NEXTPAGE_ACCESS_CONTROL_GROUPS";
}

interface PrevPage {
  type: "PREVPAGE_ACCESS_CONTROL_GROUPS";
}

interface ReceiveAccessControlGroupsAction extends FetchParams {
  type: "RECEIVE_ACCESS_CONTROL_GROUPS";
  accessControlGroups: AccessControlGroupResponse[];
}

interface RegisterAccessControlGroupActionPayload {
  externalId: string;
  tenantExternalId: string;
  propertyExternalId: string;
  unitNumber: string;
  accessControlGroupType: AccessControlGroupType;
  name: string;
  description: string;
}

interface RegisterAccessControlGroupAction
  extends RegisterAccessControlGroupActionPayload {
  type: "REGISTER_ACCESS_CONTROL_GROUP";
}

interface DeleteAccessControlGroupActionPayload {
  id: string;
}
interface DeleteAccessControlGroupAction
  extends DeleteAccessControlGroupActionPayload {
  type: "DELETE_ACCESS_CONTROL_GROUP";
}

type KnownAction =
  | RequestAccessControlGroupsAction
  | SetFetchParams
  | NextPage
  | PrevPage
  | ReceiveAccessControlGroupsAction
  | RegisterAccessControlGroupAction
  | DeleteAccessControlGroupAction;

export const actionCreators = {
  setFetchParamAccessControlGroups:
    ({
      page,
      pageSize,
      searchTerm,
      groupType,
      propertyExternalId,
    }: AccessControlGroupsFiltersParams): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      const state = getState();
      if (state.accessControlGroups.isLoading) return;

      dispatch({
        type: "SETFETCHPARAMS_ACCESS_CONTROL_GROUPS",
        page,
        pageSize,
        searchTerm,
        groupType,
        propertyExternalId,
      });

      await dispatch(actionCreators.requestAccessControlGroups() as any);
    },
  nextAccessControlGroupPage:
    (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
      const state = getState();
      if (state.accessControlGroups.isLoading) return;

      dispatch({
        type: "NEXTPAGE_ACCESS_CONTROL_GROUPS",
      });

      await dispatch(actionCreators.requestAccessControlGroups() as any);
    },
  prevAccessControlGroupPage:
    (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
      const state = getState();
      if (state.accessControlGroups.isLoading) return;

      dispatch({
        type: "PREVPAGE_ACCESS_CONTROL_GROUPS",
      });

      await dispatch(actionCreators.requestAccessControlGroups() as any);
    },
  setPageSize:
    (pageSize: number): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      const state = getState();
      const { page, searchTerm, groupType, propertyExternalId } =
        state.accessControlGroups;
      dispatch({
        type: "SETFETCHPARAMS_ACCESS_CONTROL_GROUPS",
        pageSize,
        page,
        searchTerm,
        groupType,
        propertyExternalId,
      });

      await dispatch(actionCreators.requestAccessControlGroups() as any);
    },
  requestAccessControlGroups:
    (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
      const {
        accessControlGroups: {
          page,
          pageSize,
          searchTerm,
          accessControlGroups: curAccessControlGroups,
          groupType,
          propertyExternalId,
        },
      } = getState();
      dispatch({
        type: "REQUEST_ACCESS_CONTROL_GROUPS",
      });
      try {
        const data = await getAccessControlGroups(
          page,
          pageSize,
          searchTerm !== "" ? searchTerm : undefined,
          groupType,
          propertyExternalId
        );
        dispatch({
          type: "RECEIVE_ACCESS_CONTROL_GROUPS",
          accessControlGroups: data,
          page,
          pageSize,
          searchTerm,
        });
        return data;
      } catch (_error) {
        dispatch({
          type: "RECEIVE_ACCESS_CONTROL_GROUPS",
          accessControlGroups: curAccessControlGroups,
          page,
          pageSize,
          searchTerm,
        });
      }
    },

  registerAccessControlGroup:
    (
      info: RegisterAccessControlGroupActionPayload
    ): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      const json = await apiClientFactory().postAccessControlGroup({
        externalId: info.externalId,
        tenantExternalId: info.tenantExternalId,
        propertyExternalId: info.propertyExternalId,
        unitNumber: info.unitNumber,
        type: info.accessControlGroupType,
        name: info.name,
        description: info.description,
      });

      return json;
    },

  addLockToGroup:
    (info: {
      accessControlGroupId: string;
      externalId: string;
      siteId: string;
    }): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      const json = await apiClientFactory().addLockToAccessControlGroup(
        info.accessControlGroupId,
        {
          externalId: info.externalId,
          siteId: info.siteId,
          credentialType: KeyRequestCredentialType.MobileKey,
        }
      );

      return json;
    },

  deleteAccessControlGroup:
    (
      info: DeleteAccessControlGroupActionPayload
    ): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      await apiClientFactory().deleteAccessControlGroup(info.id);

      await dispatch(actionCreators.requestAccessControlGroups() as any);
    },
};

const unloadedState: AccessControlGroupsState = {
  accessControlGroups: [],
  isLoading: false,
  page: 0,
  pageSize: defaultPageSize,
  searchTerm: "",
  groupType: "",
  propertyExternalId: "",
};

export const reducer: Reducer<AccessControlGroupsState> = (
  state: AccessControlGroupsState = unloadedState,
  incomingAction: Action
) => {
  const action = incomingAction as KnownAction;
  switch (action.type) {
    case "REQUEST_ACCESS_CONTROL_GROUPS":
      return {
        ...state,
        isLoading: true,
      };
    case "RECEIVE_ACCESS_CONTROL_GROUPS":
      return {
        ...state,
        accessControlGroups: action.accessControlGroups,
        isLoading: false,
        page: action.page,
        pageSize: action.pageSize,
        searchTerm: action.searchTerm,
      };

    case "SETFETCHPARAMS_ACCESS_CONTROL_GROUPS":
      const { groupType, propertyExternalId } = action;
      const newState = reduceSetFetchParams(state.isLoading, action, state);
      return {
        ...newState,
        groupType,
        propertyExternalId,
      };
    case "NEXTPAGE_ACCESS_CONTROL_GROUPS":
      return reduceNextPage(state.accessControlGroups, state.isLoading, state);
    case "PREVPAGE_ACCESS_CONTROL_GROUPS":
      return reducePrevPage(state.isLoading, state);

    case "REGISTER_ACCESS_CONTROL_GROUP":
    case "DELETE_ACCESS_CONTROL_GROUP":
    default:
      return state;
  }
};

async function getAccessControlGroups(
  page: number | undefined,
  pageSize: number | undefined,
  searchString: string | undefined,
  groupType: string | undefined,
  propertyExternalId: string | undefined
) {
  const queryParams = new URLSearchParams();
  if (page !== undefined) {
    queryParams.append("page", page.toString());
  }
  if (pageSize !== undefined) {
    queryParams.append("pageSize", pageSize.toString());
  }
  if (searchString !== undefined) {
    queryParams.append("searchString", searchString);
  }
  if (groupType !== undefined && groupType !== "") {
    queryParams.append("type", groupType);
  }
  if (propertyExternalId !== undefined && propertyExternalId !== "") {
    queryParams.append("propertyExternalId", propertyExternalId);
  }

  const url = `${BASE_API_URL}/api/AccessControlGroups?${queryParams.toString()}`;
  const response = await axios.get<AccessControlGroupResponse[]>(url);

  return response.data;
}
